import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import gql from 'graphql-tag';
import { useCallback, useState } from 'react';
import { useToggleMfaStatusMutation } from './__generated__/MfaOrganizationModel.graphql';

type Props = {
  enableMfa: boolean;
  id: string;
  isOpen: React.ComponentProps<typeof Modal>['isOpen'];
  onClose: React.ComponentProps<typeof Modal>['onClose'];
  onSuccess: (data: { name: string; enableMfa: boolean }) => void;
};

export function MfaOrganizationModel({ id, enableMfa, isOpen, onClose, onSuccess }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <MfaOrganizationModalContent
          enableMfa={enableMfa}
          id={id}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </ModalContent>
    </Modal>
  );
}

function MfaOrganizationModalContent({
  enableMfa,
  id,
  onClose,
  onSuccess,
}: {
  enableMfa: boolean;
  id: string;
  onClose: Props['onClose'];
  onSuccess: Props['onSuccess'];
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toggleMfaStatus] = useToggleMfaStatusMutation();

  const toast = useToast({ position: 'bottom-right' });

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const { data } = await toggleMfaStatus({
        variables: {
          input: {
            organizationId: id,
            mfaEnabled: enableMfa,
          },
        },
      });

      if (!data || !data.toggleMfaStatus) {
        throw new Error('Mfa status change failed');
      }
      if (data && data.toggleMfaStatus != undefined) {
        const name = data.toggleMfaStatus.name;
        setIsSubmitting(false);
        onSuccess({ name, enableMfa });
      }
    } catch (err) {
      onClose();
      setIsSubmitting(false);
      toast({
        title: 'Could not change mfa for the organization',
        description: 'Unknown internal server error',
        status: 'error',
      });
    }
  }, [toggleMfaStatus, id, enableMfa, onSuccess, onClose, toast]);

  return (
    <>
      <ModalHeader>Update Mfa Status</ModalHeader>
      <ModalBody>
        <Box mb={4}>
          <Text mt={2} color="red.600">
            Please note that this action will impact all users in the organization.
          </Text>
        </Box>

        <ModalFooter>
          <Box>
            <Button variant="ghost" isDisabled={isSubmitting} onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              type="submit"
              variant="solid"
              isDisabled={isSubmitting}
              onClick={() => onSubmit()}
            >
              Confirm
            </Button>
          </Box>
        </ModalFooter>
      </ModalBody>
    </>
  );
}

MfaOrganizationModel.graphql = {
  mutations: {
    ToggleMfaStatus: gql`
      mutation ToggleMfaStatus($input: ToggleMfaInput!) {
        toggleMfaStatus(input: $input) {
          id
          name
        }
      }
    `,
  },
};
